import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`While most of my professional experience so far has been in the backend, I am
also keen to work on the frontend's shiny things.`}</p>
    <p>{`The website is implemented with gatsbyjs and styled with bulma. Most content pages
are written in MDX, a subset of Markdown that allows using JSX components together
with content. It is deployed on netlify using its continuous deployment
integration with gitlab.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      